<template>
  <div class="bg-white" style="min-height: 87vh;">
    <div class="padding-lg flex justify-content-between aligin-items-center">
      <!-- <el-button type="primary">订单列表</el-button> -->
      <el-radio-group v-model="radio1">
        <el-radio-button label="订单列表"></el-radio-button>
        <el-radio-button label="创建订单"></el-radio-button>
        <el-radio-button label="创建订单"></el-radio-button>
      </el-radio-group>
      <div class="flex aligin-items-center" v-if="radio1 == '订单列表'">
        <div>
          <el-input placeholder="搜索" v-model="customerName" class="input-with-select" style="width: 400px;">
            <el-select v-model="customerSelect" slot="prepend" placeholder="客户姓名" style="width: 110px;">
              <el-option label="餐厅名" value="1"></el-option>
              <el-option label="订单号" value="2"></el-option>
              <el-option label="用户电话" value="3"></el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <div class="margin-left cursor" @click="isShow = !isShow">
          过滤
          <i class=" el-icon--right" :class="isShow == false ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
        </div>
      </div>
    </div>

    <div class="padding-lr-lg" v-if="radio1 == '订单列表' && isShow">
      <div class="width-100">
        <div class="flex width-100 border-grey aligin-items-center">
          <span class="bg-light-gray padding-sm text-center" style="width: 18%;">建单时间</span>
          <div class="padding-xs margin-left">
            <el-select v-model="value" placeholder="请选择" size="mini"><el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" /></el-select>
          </div>
          <div class="padding-xs margin-left"><el-date-picker size="mini" v-model="value1" type="date" placeholder="选择日期"></el-date-picker></div>
        </div>
        <div class="flex width-100 border-grey aligin-items-center">
          <span class="bg-light-gray padding-sm text-center" style="width: 18%;">成交时间</span>
          <div class="padding-xs margin-left">
            <el-select v-model="value" placeholder="请选择" size="mini"><el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" /></el-select>
          </div>
          <div class="padding-xs margin-left"><el-date-picker size="mini" v-model="value1" type="date" placeholder="选择日期"></el-date-picker></div>
        </div>
        <div class="flex width-100 border-grey aligin-items-center">
          <span class="bg-light-gray padding-sm text-center" style="width: 18%;">订单状态</span>
          <div class="padding-xs margin-left">
            <el-select v-model="value" placeholder="请选择" size="mini"><el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" /></el-select>
          </div>
          <div class="padding-xs margin-left">
            <el-select v-model="value1" multiple filterable placeholder="请选择" size="mini" style="width: 350px;">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
        <div class="flex width-100 border-grey aligin-items-center">
          <span class="bg-light-gray padding-sm text-center" style="width: 18%;">预计成交时间</span>
          <div class="padding-xs margin-left">
            <el-select v-model="value" placeholder="请选择" size="mini"><el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" /></el-select>
          </div>
          <div class="padding-xs margin-left">
            <el-date-picker size="mini" v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </div>
        </div>
        <div class="flex width-100 border-grey aligin-items-center">
          <span class="bg-light-gray padding-sm text-center" style="width: 18%;">产品名称</span>
          <div class="padding-xs margin-left">
            <el-select v-model="value" placeholder="请选择" size="mini"><el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" /></el-select>
          </div>
          <div class="padding-xs margin-left">
            <el-select v-model="value1" multiple filterable placeholder="请选择" size="mini" style="width: 350px;">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
        <div class="flex width-100 border-grey aligin-items-center justify-content-between bg-light-gray padding-sm">
          <div class="text-grey">查询结果(0条匹配)</div>
          <div class="text-blue">
            <el-button size="small" type="primary">过滤</el-button>
            <span class="margin-left cursor">保存过滤项</span>
            <span class="margin-left cursor">清空条件值</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="radio1 == '创建订单'">
      <div class="padding border-grey margin">
        <div class="text-bold text-black text-lg">
          客户信息
          <span class="text-red">*</span>
        </div>
        <div class="border-dashed padding flex justify-content-center aligin-items-center bg-z margin-top cursor" @click="chooseContect">
          <i class="el-icon-s-custom el-icon--left"></i>
          点击添加客户
        </div>
      </div>
      <div class="padding border-grey margin">
        <div class="text-bold text-black text-lg">产品信息</div>
        <div class="margin-top"><el-button type="success" icon="el-icon-plus" plain size="small">添加产品</el-button></div>
        <div class="margin-top-sm">
          <el-table :data="tableData" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
            <el-table-column prop="date" label="产品名称" align="center"></el-table-column>
            <el-table-column prop="name" label="原价(元)" align="center"></el-table-column>
            <el-table-column prop="address" label="销售价(元)" align="center"></el-table-column>
            <el-table-column prop="address" label="数量" align="center"></el-table-column>
            <el-table-column prop="address" label="折扣(%)" align="center"></el-table-column>
            <el-table-column prop="address" label="小计(元)" align="center"></el-table-column>
            <el-table-column prop="address" label="操作" align="center"></el-table-column>
          </el-table>
        </div>
        <div class="flex justify-content-flexEnd padding aligin-items-center">
          <span>
            产品总计
            <el-tooltip class="item" effect="dark" content="Bottom Center 提示文字" placement="bottom"><i class="el-icon-question el-icon--right cursor text-grey"></i></el-tooltip>
            ：
          </span>
          <span class="text-yellow text-lg">￥0.00</span>
        </div>
      </div>
      <div class="padding border-grey margin">
        <div class="text-bold text-black text-lg">基础信息</div>
        <div class="margin-top width-100 flex" style="padding-left: 50px;">
          <el-form ref="form" :model="form" label-width="120px" label-position="left">
            <el-form-item>
              <span slot="label">
                  <span class="span-box flex aligin-items-center">
                      <span> 主题 </span>
                      <el-tooltip class="item" effect="dark" content="Bottom Center 提示文字" placement="bottom"><i class="el-icon-question el-icon--right cursor text-grey"></i></el-tooltip>
                  </span>
              </span>
              <el-input v-model="form.name" placeholder="选填,200字以内" />
             
            </el-form-item>
            <el-form-item label="订单状态">
              <el-select v-model="form.region" placeholder="请选择">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="金额"><el-input v-model="form.price" placeholder="选填,200字以内" style="width: 217px;" /></el-form-item>
            <el-form-item >
              <span slot="label">
                  <span class="span-box flex aligin-items-center">
                      <span>预计成交时间</span>
                      <el-tooltip class="item" effect="dark" content="Bottom Center 提示文字" placement="bottom"><i class="el-icon-question el-icon--right cursor text-grey"></i></el-tooltip>
                  </span>
              </span>
              <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 217px;"></el-date-picker>
            </el-form-item>
            <el-form-item label="产品说明"><el-input v-model="form.name" placeholder="选填,200字以内" /></el-form-item>
            <el-form-item label="备注"><el-input type="textarea" v-model="form.desc" placeholder="选填,200字以内"></el-input></el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
              <el-button>取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radio1: '订单列表',
      customerName: '',
      customerSelect: '',
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ],
      value: '',
      value1: '',
      isShow: true,
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '1516 弄'
        }
      ],
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        price: '0.00'
      }
    };
  },
  created() {},
  mounted() {},
  methods: {
    chooseContect() {
      this.$Dialog.ChooseContact().then(res => { 
      })
    },
    onSubmit() { 
    }
  }
};
</script>

<style scoped>
  >>>.el-form {
    min-width: 500px;
  }
</style>
